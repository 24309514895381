.user-header {
  align-items: center;
  background: $color-gray-dark;
  color: $color-white;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 100%;

  .go-back {
    // display: none;
    color: $color-brand;
    cursor: pointer;
    display: block;
    font-size: 30px;
    left: 15px;
    margin-right: 15px;
    position: fixed;
  }

  figure {
    align-items: center;
    display: flex;
    justify-content: space-between;

    figcaption {
      border-left: 1px solid $user-header-figcaption;
      font-size: 11px;
      font-weight: 600;
      line-height: 14px;
      margin-left: 10px;
      padding-left: 10px;
      text-transform: uppercase;

      span {
        display: block;
      }
    }
  }

  .btn-user {
    align-self: flex-end;
    background: none;
    border: 0;
    position: fixed;
    right: 10px;
    top: 7px;

    i {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 20;

    .go-back {
      display: block;
    }
  }
}
