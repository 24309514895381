// Procedures List
.procedure-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.procedure-name {
  margin-bottom: 10px;
}

// Procedures Item
.procedures-item {
  background: $color-procedures;
  border-radius: 5px;
  color: $color-white;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;
  width: 400px;
  cursor: pointer;
  $height: 5px;

  a.mark-revised-btn {
    width: 60px;
    float: right;
    font-size: 20px;
    padding: 10px;
  }

  .bar {
    display: flex;
    height: $height;
    margin-bottom: 10px;
    width: 100%;
  }

  .step {
    background: $procedures-goals;
    height: $height;
    margin-right: 1px;
    width: 100%;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.done {
      background: $procedures-done;
    }
  }

  .steps-indicator {
    border-bottom: 1px solid $color-steps-indicator;
    color: $color-steps;
    font-size: 11px;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
  }

  p,
  span {
    opacity: .7;
    text-transform: uppercase;
  }

  .success {
    margin-right: 7px;
    padding-right: 7px;
    position: relative;

    &::after {
      background: $blanco-0-7;
      content: ' ';
      height: 2px;
      margin-top: -1px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 2px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
}

// Procedures Detail
.procedures-detail {
  article {
    border-bottom: 1px solid $color-eee;
    margin: 20px -15px;
    padding: 0 15px 20px;

    h3 {
      color: $color-procedures;
    }

    &:last-child {
      border-bottom: 0;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .procedure-list {
    a {
      width: 100%;
    }
  }
}
