// Login

$left: 40%;

.login {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  text-align: center;

  .content {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  header {
    align-self: flex-start;
    color: $color-white;
    position: absolute;
    figure {
      margin-bottom: 0;
      img {
        background: $color-brand;
        margin-top: -15px;
        padding: 30px 15px 15px;
      }
      figcaption {
        font-weight: bold;
        margin-top: 50px;
        text-transform: uppercase;
      }
    }
  }

  &__left {
    @if $production {
      background: url('../../img/front/background.jpg') -360px 0 / cover no-repeat;
    } @else {
      background: url('../dist/images/background.jpg') -360px 0 / cover no-repeat;
    }
    color: $color-white;
    flex-basis: $left;
    justify-content: center;
    overflow: hidden;
    position: relative;

    header {
      width: 100%;
    }

    &:before {
      background-color: rgba(55,66,74,0.9);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    p {
      align-self: center;
      font-size: 16px;
    }
  }

  &__right {
    background: $color-white;
    flex-basis: calc(100% - #{$left});
    justify-content: center;

    header {
      display: none;
    }

    .msg-recover {
      align-items: center;
      color: $color-gray-dark;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      h2 {
        font-size: 22px;
      }

      & + form {
        margin-top: 0
      }
    }

    h3 {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__left,
  &__right {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
  }

  // Login Form
  form {
    margin: 4em auto 0;
    padding: 0 30px;
    width: 350px;

    .btn {
      background: $color-brand;
      border: 0;
      font-weight: 600;
      margin: 30px auto 15px;
      padding: 15px 50px;
    }

    a {
      display: block;
      text-decoration: underline;
      &.btn {
        display: inline-block;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__left {
      display: none;
    }

    &__right {
      @if $production {
        background: url('../../img/front/background.jpg') -360px 0 / cover no-repeat;
      } @else {
        background: url('../dist/images/background.jpg') -360px 0 / cover no-repeat;
      }
      flex-basis: 100%;
      position: relative;
      &:before {
        background-color: rgba(55,66,74,0.9);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      header {
        display: block;
        width: 100%;
      }

      .msg-recover {
        color: $color-white;
        margin-bottom: 30px;
        margin-top: 150px;
      }

      a {
        color: $color-white;
        text-decoration: underline;
      }

      h3 { display: none; }

      .form {
        padding-top: 150px;
      }

      form {
        .form-control,
        a {
          color: $color-white;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
      &__right {
        padding: 30px;

        .content,
        .form {
          width: 100%;
        }

        header {
          figure {
            img {
              margin-top: -30px;
            }

            figcaption {
              margin-top: 40px;
            }
          }
        }
        form {
          align-self: flex-end;
          padding: 0;
          width: 100%;
        }
      }
    }
}
