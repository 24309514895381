.profile {

  .flex {
    padding: 0 10px;
  }

  .form {
    flex-basis: 60%;

    & + div {
      align-self: flex-end;
    }
  }

  figure {
    align-items: center;
    display: flex;

    .img {
      background-size: cover;
      border-radius: 50%;
      height: 100px;
      margin-right: 20px;
      width: 100px;
    }

    label {
      color: $color-white;
      text-transform: none;
    }

    input {
      left: 0;
      position: absolute;
      top: -200px;
    }
  }

  @media screen and (max-width: 768px) {
    .form {
      flex-basis: 100%;
    }
  }
}
