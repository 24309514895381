// sass-lint:disable no-important
.btn-principal {
  background: $color-brand;
  border-color: $color-brand;
  // box-shadow: none !important;
  color: $color-white;
  font-weight: 600;
  // outline: 0 !important;
  padding: 10px 15px;

  &:focus,
  &:active,
  &:hover {
    background: $color-brand;
    border-color: $color-brand;
    color: $color-white;
    outline: 0 !important;
  }

  &[disabled] {
    background-color: $color-disabled;
    border-color: $color-disabled;
    opacity: 1;

    &:hover {
      background-color: $color-disabled;
      border-color: $color-disabled;
    }
  }
}

@media screen and (max-width: 768px) {
  .btn-save {
    border-radius: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 20;
  }
}
