.alert {
  align-items: center;
  border: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  position: relative;

  &-icon {
    align-items: center;
    border-radius: 4px 0 0 4px;
    color: $color-white;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;

    i {
      font-size: 20px;
    }
  }

  &-warning {
    background: $color-alert;

    &-icon {
      background: $color-alert-icon;
    }
  }

  .close {
    top: 0;

    i {
      font-size: 14px;
    }
  }
}
