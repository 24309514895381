// sass-lint:disable no-important
.form-group {
  text-align: left;

  .form-control:not(.no-reset) {
    background: none;
    border-color: $color-ccc;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 1px 0;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    &::placeholder {
      font-style: italic;
    }

    &:focus {
      background: none;
      box-shadow: none;
      outline: 0;
    }
  }

  label {
    color: $color-ccc;
    font-size: 14px;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &.border {
    border-bottom: 1px solid $color-ccc;
  }

  &.noMargin {
    margin-bottom: 0;
  }
}

.radio,
.checkbox {

  label {
    align-items: center;
    display: inline-flex;
    padding-left: 0;
  }

  &:first-child {
    label {
      padding-left: 0;
    }
  }

  span {
    margin-left: 0;
    position: relative;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  input {
    margin: 3px 0 0 6px;
    opacity: 0;
    position: static;

    label {
      display: inline;
      padding: 0;
      position: relative;
      top: 2px;

      &:not(:first-child) {
        padding-left: 0;
      }
    }

    &:checked {
      & + span {
        background-color: $color-brand;
        border-color: $color-brand;
      }

      &:active,
      &:hover {
        & + span {
          background-color: $color-brand;
          border-color: $color-brand;
        }
      }
    }

    & + span {
      align-items: center;
      background-color: $color-white;
      border: 2px solid $color-ddd;
      border-radius: 3px;
      color: $color-white;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      float: left;
      font-size: 1rem;
      height: 24px;
      justify-content: center;
      margin: 0 10px 0 0;
      overflow: hidden;
      padding: 0;
      width: 24px;

      &::after {
        content: '\ed40';
        font: normal normal normal 20px/1 'Nucleo Glyph';
      }
    }
  }
}


.radio {
  input {
    & + span {
      border-radius: 50%;
    }
  }
}

.ui-autocomplete {
  background: $color-white;
  border: 1px solid $autocomplete-border;
  border-radius: 4px;
  box-shadow: 0 6px 12px $autocomplete-shadow;
  padding: 0;
  position: absolute !important;

  li {
    border-bottom: 1px solid $color-ddd;
    list-style: none;
    padding: 10px;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: $color-eee;
    }
  }
}

.input-group-addon {
  background: 0;
  border: 0;
  border-bottom: 1px solid $color-ccc;
  border-radius: 0;

  .glyphicon {
    color: $color-gray-light2;
  }
}


.form-group select.form-control:not(.no-reset) {
  border: 0;
}

.btn-bottom {
  border-top: 1px solid $color-ccc;
  margin-bottom: 30px;
  margin-top: 50px;
  padding-top: 10px;
  text-align: right;
}

p.invalid {
  color: $color-brand;
  font-size: 12px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .btn-bottom {
    border: 0;
    margin: 0;
    padding: 0;
  }
}

table.table-row-click {
  table-layout: fixed;
}
@media screen and (max-width: 768px) {
  table.table-row-click {
    table-layout: auto;
  }
}

div.auto-evaluation div.form-group.select p.help-block {
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
