$color-white: #fff;
$color-brand: #e00034;
$color-gray-dark: #37424a;
$color-gray-light: #b3b9bd;
$color-gray-light2: #979797;
$color-rotations: #afb3b7;
$color-procedures: #708594;
$color-eee: #eee;
$color-ddd: #ddd;
$color-ccc: #ccc;
$color-alert: #f4e4b8;
$color-alert-icon: #f0ce70;
$color-disabled: #f36485;
$color-menu: #21282C;

$color-steps-indicator: #8fa4b3;
$color-steps: #c3c3c3;

$procedures-goals: rgba($color-white, .3);
$procedures-done: #ff8900;

$user-header-figcaption: rgba($color-white, .2);
$blanco-0-7: rgba($color-white, .7);

$autocomplete-border: rgba(0, 0, 0, .15);
$autocomplete-shadow: rgba(0, 0, 0, .175);

$production: true;
