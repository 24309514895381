.home {
  .procedures-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;

    .procedures-item {
      margin-right: 15px;
      margin-bottom: 15px;
      width: 300px;

      h4 {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .rotation {
      margin-right: 0;
    }

    .procedures-list {
      .procedures-item {
        margin-right: 0;
        width: 100%;
      }
    }

    div.buttons {
      bottom: 0;
      margin-left: -15px;
      position: fixed;
      width: 100%;
      z-index: 20;
      div.row {
        div {
          padding: 0 5px;
          text-align: center;
          a {
            display: block;
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }
  }
}
