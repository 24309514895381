.add {
  h1 {
    padding: 0 10px;
  }

  .nav-tabs {
    margin-bottom: 20px;

    & > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      border: 0;
    }

    li {
      margin-right: 10px;
      margin-bottom: 0;
      padding-left: 10px;
      > a {
        border: 0;
        color: #999;
        padding: 10px 0;
        &:active,
        &:focus,
        &:hover {
          background: none;
          outline: 0;
        }
      }

      &.active {
        a {
          color: #666;
          border-bottom: 5px solid #666;
        }
      }
    }
  }

  .tab-pane {
    padding: 10px;
  }

  .performed_procedures{
    .checkbox {
      &.opened {
        margin: 10px -10px;
        background: #ddd;
        padding: 10px;
      }

      .procedure_result {
        display: none;

        label {
          color: #999;
          font-size: 13px;
          margin-top: 10px;
          text-transform: uppercase;
        }
      }
    }
  }


  #patients {
    $width: 60%;

    .form {
      flex-basis: $width;
      & + div {
        align-self: flex-end;
        button {
          padding: 10px 30px;
        }
      }

      label {
        font-weight: 300;
      }
    }
  }

  #procedures {
     $width: 50%;

     .flex {
       align-items: flex-start;
     }

     section {
       flex-basis: $width;

       &.anesthesia_types {
         padding-right: 15px;

         textarea {
           margin: 10px 0 20px;
         }

         .checkbox {
           .textarea {
             display: none;
             margin-bottom: 0;
           }
         }
        }

        &.performed_procedures {
         padding-left: 15px;

         h2 {
           font-size: 14px;
           font-weight: bold;
         }
       }
     }
  }

  @media screen and (max-width: 768px) {
    #patients,
    #procedures {
      .flex {
        align-items: normal;
        flex-direction: column;
      }
    }

    #procedures{
      section {
        &.anesthesia_types {
          padding-right: 0;
        }
        &.performed_procedures {
          padding-left: 0;
        }
      }
    }

    .nav-tabs {
      margin: 0 -15px 20px;

      li {
        padding-left: 15px;
      }
    }

    .tab-pane {
      padding: 0;
    }

    .performed_procedures {
      .checkbox {
        padding: 0;
        &.opened {
          margin: 5px -15px;
          padding: 15px;

          & + .checkbox:not(.opened) {
            margin-top: 10px;
          }
        }

        .form-group {
          margin-bottom: 0;
        }

      }
    }
  }
}
