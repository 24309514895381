.logged-layout {
  display: flex;

  main {
    padding: 60px 10px 10px 30px;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    main {
      padding: 60px 15px;
      width: 100%;
    }
  }
}
