.user-menu {
  background: $color-menu;
  color: $color-white;
  height: 100vh;
  padding: 10px;
  position: fixed;
  right: -240px;
  top: 0;
  transition: transform .25s;
  width: 240px;
  z-index: 21;

  &.opened {
    transform: translateX(-240px);
  }

  button {
    background: none;
    border: 0;
    float: right;

    &:active,
    &:focus,
    &:hover {
      outline: 0;
    }
  }

  figure {

    .img {
      background-size: cover;
      border: 2px solid $color-gray-light2;
      border-radius: 50%;
      height: 52px;
      width: 52px;
    }

    h2 {
      font-size: 24px;
    }
  }

  .form-group {
    label {
      color: $color-gray-dark;
      font-size: 12px;
      font-weight: 300;
    }

    select {
      border-color: $color-gray-dark;
      color: $color-white;
      height: auto;
      padding: 0 0 10px;
    }
  }

  nav {
    margin: 30px -10px 0;

    ul {
      padding: 0;
    }

    li {
      list-style-type: none;
      margin-bottom: 1px;

      &.active {
        a {
          background: $color-brand;
        }

        span {
          color: $color-white;
        }
      }
    }

    a {
      color: $color-white;
      display: block;
      font-size: 16px;
      line-height: 18px;
      padding: 10px 15px;
      transition: background .25s;

      &:active,
      &:focus,
      &:hover {
        background: $color-gray-dark;
        text-decoration: none;
      }
    }
  }
}
