.logged-menu {
  background: $color-white;
  min-height: 100vh;
  padding: 10px;
  text-align: left;
  width: 320px;

  a,
  select {
    color: $color-white;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 0;
  }

  h3 {
    display: block !important;
    text-align: left;
  }

  p {
    margin-bottom: 20px;
  }

  .form-group {
    label {
      color: $color-gray-dark;
      font-size: 12px;
      font-weight: 300;
    }

    select {
      border-color: $color-gray-dark;
      height: auto;
      padding: 0 0 10px;
    }
  }

  nav {
    margin: 30px -10px 0;

    ul {
      padding: 0;
    }

    li {
      list-style-type: none;
      margin-bottom: 1px;

      &.active {
        a {
          background: $color-brand;
        }

        i {
          color: $color-white;
        }
      }
    }

    a {
      background: $color-gray-dark;
      display: block;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      padding: 17px 10px;
      position: relative;
      text-decoration: none;

      figure {
        align-items: center;
        display: flex;

        i {
          color: $color-gray-light;
          font-size: 32px;
          margin-right: 10px;
        }
      }

      figcaption {
        text-align: left;
      }

      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
      }

      &::after {
        content: '\eed8';
        font: normal normal normal 22px/1 'Nucleo Glyph';
        margin-top: -11px;
        position: absolute;
        right: 5px;
        top: 50%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    background: $color-white;
    color: $color-gray-dark;
    // display: none;
    height: 100vh;
    left: -100%;
    position: fixed;
    top: 0;
    transition: transform .25s;
    width: 100%;
    z-index: 22;

    &.opened {
      transform: translateX(100%);
    }

    h3 {
      color: $color-gray-dark;
      display: block !important;
    }
  }
}
