/*
Theme Name: UAndes
Theme URI:
Author: Carlos Sánchez
Author URI: https://github.com/sanchezcarlos1986
Description:
Version: 1.0
Tags: html5, css3, responsive design, responsive-layout, icofonts.
Text Domain:
*/

h1, h3 {
  color: #444;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@import './base/variables';
@import './nucleo-glyph';

@import './base/alert';
@import './base/buttons';
@import './base/forms';
@import './base/logged-menu';
@import './base/procedures';
@import './base/rotation';
@import './base/user-header';
@import './base/user-menu';

@import './add';
@import './home';
@import './logged-layout';
@import './login';
@import './profile';
