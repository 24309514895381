// sass-lint:disable no-important
.rotation {
  background: $color-rotations;
  border-radius: 5px;
  color: $color-white;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;
  position: relative;
  width: 300px !important;

  &:active,
  &:focus,
  &:hover {
    outline: 0;
  }

  .state,
  .tutor,
  .year {
    opacity: .7;
    text-transform: uppercase;
  }

  .months {
    text-transform: uppercase;

    .month {
      margin-left: 6px;
    }
  }

  .state,
  .tutor {

    font-size: 12px;
    font-weight: 600;
  }

  .tutor {
    border-bottom: 1px solid $blanco-0-7;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    height: 44px;
  }

  .score {
    align-items: center;
    background: $color-gray-dark;
    border-radius: 5px;
    display: flex;
    font-size: 30px;
    font-weight: bold;
    height: 54px;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 54px;

    a {
      color: $color-white;
      text-decoration: none;
    }
  }

  &.past {
    h4 {
      padding-right: 64px;
    }
  }

  &.active {
    background: $color-brand;
  }

  &.future {
    background: $color-gray-dark;
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
